// import { closeItems, openItems } from "./MapQuest";
import React from "react";
import "./Pedometer.css";

import { update, getDatabase, ref, onValue } from "firebase/database";
import { playerStats } from "./MapQuest";
import { getSignedIn } from "../SignInSignUp/SignInSignUp";
import { getPlayerTag } from "./MapQuest";

export function updatePedoMeter(steps, load = false) {
  // document.getElementById("pedometerInfo").innerHTML = `${Math.floor(
  //   steps
  // )} steps`;
  // if (getSignedIn() && !load) {
  //   const db = getDatabase();
  //   update(ref(db, `players/${getPlayerTag()}`), {
  //     steps: steps,
  //   });
  // }
}

export function loadSteps() {
  playerStats.walkDist = 0;

  const db = getDatabase();
  const playerRef = ref(db, `players/${getPlayerTag()}`);

  console.log(db, playerRef);

  onValue(playerRef, (snapshot) => {
    console.log("IN VAL");
    const data = snapshot.val();

    playerStats.walkDist = data.steps;
    updatePedoMeter(playerStats.walkDist, true);
  });
}

export function Pedometer() {
  return <pedometer-butn id="pedometerInfo">Pedometer</pedometer-butn>;
}
