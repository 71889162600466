import React, { useState } from "react";
import "./SignInSignUp.css";
import Icons from "../../img/Icons";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { updateNav } from "../../Navigation/Navbar";
// import Router from "router";
import axios from "axios";
import { setCookies, getCookies } from "../MapQuest/MapQuest";
// import express from "express";
import { playerStats, getPlayerTag } from "../MapQuest/MapQuest";
import { useEffect } from "react";
import { loadSteps } from "../MapQuest/Pedometer";
import {
  getDatabase,
  ref,
  set,
  update,
  get,
  onValue,
  removem,
} from "firebase/database";
import { loadInventory } from "../MapQuest/Inventory";

const firebaseConfig = {
  apiKey: "AIzaSyDoyyOKhfOx6RMrud3l877PelAxTjWvpJU",
  authDomain: "street-quest-11545.firebaseapp.com",
  databaseURL:
    "https://street-quest-11545-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "street-quest-11545",
  storageBucket: "street-quest-11545.appspot.com",
  messagingSenderId: "489751953754",
  appId: "1:489751953754:web:b1f063e74f0ae00c607363",
  measurementId: "G-35BC1H35SN",
};

// Router.get("/auth/discord/login", async (ctx) => {
//   const url =
//     "https://discord.com/oauth2/authorize?client_id=1268157097569419324&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4000%2Fauth%2Fdiscord%2Fcallback&scope=identify";

//   ctx.redirect(url);
// });

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let auth = getAuth();
let googleProvider = new GoogleAuthProvider();
let userInfo = undefined;

console.log("CODE:", process.env);

let discordCallbackString = "/auth/discord/callback";

console.log("COOKIES LOAD: ", getCookies(document.cookie));

export function getSignedIn() {
  return (
    sessionStorage.getItem("signedIn") &&
    sessionStorage.getItem("signedIn") != "false"
  );
}

async function checkDiscordCallback() {
  let pathName = window.location.pathname;
  console.log("PATH : ", pathName);
  if (pathName == discordCallbackString) {
    console.log("IN DISCORD LOGIN");

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);

    console.log(" GETTING CODE", urlParams.get("code"));

    if (urlParams.get("code")) {
      console.log("IN CODE");
      const discordCode = urlParams.get("code");

      console.log(
        "IN CODE INFO,",
        process.env.REACT_APP_DISCORD_CLIENT_ID,
        process.env.REACT_APP_DISCORD_CLIENT_SECRET,
        process.env.REACT_APP_DISCORD_REDIRECT_URI
      );

      const params = new URLSearchParams({
        grant_type: "authorization_code",
        discordCode,
        redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
      });

      console.log("IN CODE 3", params);
      console.log(`IN CODE |${discordCode}|`);
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        // "Accept-Encoding": "application/x-www-form-urlencoded",
      };

      console.log("IN CODE 4");

      // const response = await fetch("https://discord.com/api/v10/oauth2/token", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     "accept-encoding": "application/x-www-form-urlencoded",
      //   },
      //   body: new URLSearchParams({
      //     client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
      //     client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
      //     grant_type: "authorization_code",
      //     code: discordCode,
      //     redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
      //   }),
      // });

      let options = {
        url: "https://discord.com/api/oauth2/token",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
          client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
          grant_type: "client_credentials",
          code: discordCode,
          redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
          scope: "identify email",
        }),
      };

      let response = await fetch(
        "https://discord.com/api/oauth2/token",
        options
      )
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          return data;
        });

      // // request.post())
      // const response = await axios.post(
      //   "https://discord.com/api/v10/oauth2/token",
      //   params,
      //   {
      //     headers,
      //   },

      //   (process.env.REACT_APP_DISCORD_CLIENT_ID,
      //   process.env.REACT_APP_DISCORD_CLIENT_SECRET)
      // );

      const userResponse = await axios.get(
        "https://discord.com/api/users/@me",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        }
      );

      const { id, username, avatar } = userResponse.data;

      console.log("DISC DATA", userResponse.data);

      let profileURL = `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`;

      await setUserSignIn(id, username, "discord", profileURL);

      // signInWithCustomToken(auth, response.access_token);
    }

    window.location.replace("/ ");
  }
}

function removeAuthIfSignedOut() {
  if (sessionStorage.getItem("signedIn") == "false") {
    signOut(auth);
  }
}

removeAuthIfSignedOut();

async function setUserSignIn(
  id,
  name,
  type,
  profilePic,
  accessToken = "null",
  refreshToken = "null"
) {
  playerStats.id = id;
  playerStats.name = name;
  playerStats.loginType = type;
  playerStats.login = true;

  loadInventory();

  console.log("SETTING COOKIE +");

  sessionStorage.setItem("signedIn", type);
  sessionStorage.setItem("id", id);
  sessionStorage.setItem("profilePic", profilePic);
  sessionStorage.setItem("name", name);
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);

  console.log("LOGGING IN INFO ------------");
  console.log(sessionStorage.getItem("signedIn"));
  console.log(sessionStorage.getItem("id"));
  console.log(sessionStorage.getItem("name"));
  console.log(sessionStorage.getItem("profilePic"));
  console.log(sessionStorage.getItem("accessToken"));
  console.log(sessionStorage.getItem("refreshToken"));

  // Fill auth for discord sign ins;

  if (sessionStorage.getItem("signedIn") == "discord") {
    await signInWithEmailAndPassword(
      auth,
      "arhiveorg@gmail.com",
      "DISCORDPASS1111"
    )
      .then((userCredential) => {
        console.log("LOGGED IN SUCCESS");
        console.log("AUTH,", auth);
        // ...
      })
      .catch((error) => {
        signUserOut();
      });
  }

  databaseSignIn();
}

function databaseSignIn() {
  if (sessionStorage.getItem("signedIn") == "false") return;

  let db = getDatabase();
  let playerRef = ref(db, "players/" + getPlayerTag());
  get(playerRef).then((snapshot) => {
    if (!snapshot.exists() && sessionStorage.getItem("signedIn") != "false") {
      update(playerRef, {
        items: "",
        steps: 0,
      });
    }
  });
}

function pageLoadLogin() {
  console.log("LOGGING IN INFO ------------");
  console.log(sessionStorage.getItem("signedIn"));
  console.log(sessionStorage.getItem("id"));
  console.log(sessionStorage.getItem("name"));
  console.log(sessionStorage.getItem("profilePic"));
  console.log(sessionStorage.getItem("accessToken"));
  console.log(sessionStorage.getItem("refreshToken"));
  console.log("---------------------------");

  loadInventory();
}
pageLoadLogin();

function updateSignInInfo() {
  if (sessionStorage.getItem("signedIn") == "false") return;
  if (!document.getElementById("signInInfo")) return;

  document.getElementById("signInInfo").innerHTML = `
    ${sessionStorage.getItem("name")}
      <br />
      ${sessionStorage.getItem("signedIn")}
      <br />
      ${sessionStorage.getItem("id")}
    `;

  document
    .getElementById("profile-pic")
    .setAttribute("src", sessionStorage.getItem("profilePic"));
}
export function loadPageSignUp() {
  // console.log("LOADING PAGE SIGN UP");
  // getUserSignedIn();
  // console.log("LOADTAG: ", playerStats.loginType, playerStats.id);
  loadInventory();
  // loadSteps();

  if (!getSignedIn()) {
    let thisHref = window.location.href;
    if (
      !thisHref.includes("SignInSignUp") &&
      // !thisHref.includes("mapQuest") &&
      !thisHref.includes("auth/discord/callback") &&
      !thisHref.includes("disallowed")
    ) {
      window.location.replace("/SignInSignUp  ");
    }
  }
}
loadPageSignUp();

function googleSignIn() {
  console.log("TRY REDIRECT");
  signInWithPopup(auth, googleProvider).then((result) => {
    // console.log("GOOGLE SIGN IN ", result);
    if (result.user) {
      setUserSignIn(
        result.user.uid,
        result.user.displayName,
        "google",
        `${result.user.photoURL}`
      );
    }

    window.location.replace("/");
  });
}

console.log("PLAYER LOGIN ", playerStats.login);
checkDiscordCallback();

function signUserOut() {
  if (sessionStorage.getItem("signedIn") == "google") {
    signOut(auth)
      .then(() => {
        sessionStorage.setItem("signedIn", "false");
        window.location.replace("/SignInSignUp  ");
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  } else if (sessionStorage.getItem("signedIn") == "discord") {
    sessionStorage.setItem("signedIn", "false");
    window.location.replace("/SignInSignUp  ");
  }
}

export const SignInSignUp = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  const [signedIn, setSignIn] = useState(false);

  // const [countryCodes, setCountryCodes] = useState([]);
  // const [months, setMonths] = useState([]);
  // const [years, setYears] = useState([]);

  // const populateCountryCodes = () => {
  //   const codes = [
  //     { code: "+1", country: "United States" },
  //     { code: "+44", country: "United Kingdom" },
  //     // Add more country codes as needed
  //   ];
  //   setCountryCodes(codes);

  //   // Set the default country code based on user's location
  //   const userCountry = "United States"; // Replace with code to detect user's country
  //   const defaultCountryCode =
  //     codes.find(({ country }) => country === userCountry)?.code || "+1";
  //   // Set the default country code in the state or update the select element
  // };

  // const populateMonthAndYear = () => {
  //   const currentYear = new Date().getFullYear();
  //   const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
  //   const yearOptions = Array.from(
  //     { length: 101 },
  //     (_, i) => currentYear - 100 + i
  //   );
  //   setMonths(monthOptions);
  //   setYears(yearOptions);
  // };

  //   const googleBtns = document.getElementsByClassName("google-btn");

  //   console.log("BTNS",googleBtns)
  //   for(var i = 0; i < googleBtns.length; i++){
  //
  //  }

  const showSignInForm = () => {
    setIsSignUp(false);
  };

  const toggleForms = () => {
    setIsSignUp((prevState) => !prevState);
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();

    // Get the form input values
    const firstName = document.getElementById("signInFirstName").value;
    const lastName = document.getElementById("signInLastName").value;
    const password = document.getElementById("signInPassword").value;

    try {
      // Send a request to the server for authentication
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, password }),
      });

      // Handle the server response
      if (response.ok) {
        // Authentication successful
        const data = await response.json();
        // Store the authentication token or session information
        localStorage.setItem("authToken", data.token);
        // Redirect the user to a protected route or dashboard
        window.location.href = "/dashboard";
      } else {
        // Authentication failed
        const error = await response.text();
        console.error("Login failed:", error);
        // Display an error message to the user
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Display an error message to the user
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    // Get the form input values
    const firstName = document.getElementById("signUpFirstName").value;
    const lastName = document.getElementById("signUpLastName").value;
    const email = document.getElementById("signUpEmail").value;
    const phone = document.getElementById("signUpPhone").value;
    const password = document.getElementById("signUpPassword").value;
    const confirmPassword = document.getElementById(
      "signUpConfirmPassword"
    ).value;

    // Validate the form inputs
    if (password !== confirmPassword) {
      return;
    }

    try {
      // Send a request to the server to create a new user account
      const response = await fetch("/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, email, phone, password }),
      });

      // Handle the server response
      if (response.ok) {
        // Sign-up successful
        // Redirect the user to the login page or perform additional actions
      } else {
        // Sign-up failed
        const error = await response.text();
        console.error("Sign-up failed:", error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  function discordSignIn() {
    window.location.replace(
      "https://discord.com/oauth2/authorize?client_id=1268157097569419324&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fdiscord%2Fcallback&scope=identify+email"
    );
  }
  React.useEffect(function () {
    if (getSignedIn()) {
      updateSignInInfo();
    }
  });

  return (
    <div
      className="card-background"
      style={{ backgroundImage: `url(${Icons.iconBackground})` }}
    >
      {/* for users signed in ------------------------------------------------------------------------------ */}
      {!getSignedIn() ? (
        <div className="container">
          <div className="toggle-container">
            <div className="form-container">
              {/* ------------------------------------------------new logo ------------- */}
              <div className="cardP">
                <div className="Card__logo">
                  <img src={Icons.iconSQLogo} alt="Game logo" />
                </div>
                <h1 className="Home_header SignUp_Card__text-gameName">
                  Street Quest
                </h1>
                <h2 className="tradeMark SignUp_Card__text-SubTitle">TM</h2>
              </div>
              {/* --------------------------------------------------- */}
              {/* <div className="slider">
                <span className="slider-text">Sign In</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="toggle"
                    checked={isSignUp}
                    onChange={toggleForms}
                  />
                  <span className="slider-toggle round"></span>
                </label>
                <span className="slider-text">Sign Up</span>
              </div> */}
              {!isSignUp ? (
                /* Sign-in form ----------------------------------------------------------- */

                <form-signin
                  className="sign-in-form"
                  onSubmit={handleSignInSubmit}
                >
                  {/* <h1 className="signIn_header-text">Sign In</h1>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signInFirstName"
                      placeholder="First Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                    <input
                      type="text"
                      id="signInLastName"
                      placeholder="Last Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      id="signInPassword"
                      placeholder="Password"
                      required
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <input type="checkbox" className="rememberMe_label" />
                    <label htmlFor="rememberMe">Remember Me &nbsp; </label>                    
                    <a
                      href="#"
                      className="forgotPassword_link"
                      // id="forgotPassword"
                    >
                      Forgot Password?
                    </a>
                  </div> */}
                  {/* <button className="signIn_button" type="submit">
                    Log In
                  </button> */}

                  <div className="social-login">
                    <button
                      // this classnames --------------------
                      className="social-btn google-btn"
                      onClick={googleSignIn}
                    >
                      <img
                        className="google-logo"
                        src={Icons.iconGoogle}
                        alt="Google Logo"
                      />
                    </button>
                    <button
                      // this is different from this classnames --------------------
                      className="social-login discord-login"
                      onClick={discordSignIn}
                      id="discord"
                    >
                      <img
                        class="discord-logo"
                        src={Icons.iconDiscord}
                        alt="Discord Logo"
                      />
                      {/* Sign in with discord */}
                    </button>{" "}
                  </div>
                </form-signin>
              ) : (
                /* Sign-up form ----------------------------------------------------------- */

                <form-signup
                  className="sign-up-form"
                  onSubmit={handleSignUpSubmit}
                >
                  <h2 className="signUp_header-text">Sign Up</h2>
                  <div className="form-group">
                    <input
                      type="text"
                      id="signUpFirstName"
                      placeholder="First Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                    <input
                      type="text"
                      id="signUpLastName"
                      placeholder="Last Name"
                      pattern="[A-Za-z]+"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="signUpEmail"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      id="signUpPhone"
                      placeholder="Phone Number"
                      required
                    />
                    {/* <select id="countryCode">
                    {countryCodes.map(({ code, country }) => (
                      <option
                        key={code}
                        value={code}
                      >{`${code} (${country})`}</option>
                    ))}
                  </select> */}
                  </div>
                  {/* <div className="form-group">
                  <select id="signUpMonth" required>
                    <option value="">Month</option>
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  <select id="signUpYear" required>
                    <option value="">Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div> */}
                  <div className="form-group">
                    <input
                      type="password"
                      id="signUpPassword"
                      placeholder="Password"
                      required
                    />
                    <input
                      type="password"
                      id="signUpConfirmPassword"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>

                  <div className="social-login">
                    <button
                      onClick={googleSignIn}
                      className="social-btn google-btn"
                    >
                      <img
                        className="google-logo"
                        src={Icons.iconGoogle}
                        alt="Google Logo"
                      />
                    </button>

                    {/* <button className="social-btn apple-btn">Apple login</button>
                  <button className="social-btn facebook-btn">
                    Facebook login
                  </button> */}
                  </div>

                  <button className="signUp_button" type="submit">
                    Sign Up
                  </button>
                </form-signup>
              )}
            </div>
          </div>
        </div>
      ) : (
        <profile-page className="profile_page-container">
          {/* Profile Picture */}
          <div className="profile-page__picture-container">
            <img
              referrerPolicy="no-referrer"
              id="profile-pic"
              className="profile-page__picture"
              src={Icons.iconPerson} // Replace with the actual path or variable for the profile picture
              alt="ProfilePicture"
            />
          </div>
          <sign-in-info id="signInInfo" className="sign_in-info"></sign-in-info>
          <sign_out-btn
            onClick={signUserOut}
            id="signOut"
            className="sign_out-temp"
          >
            Sign out
          </sign_out-btn>
        </profile-page>
      )}
    </div>
  );
};

export default SignInSignUp;

// when you sign in you should be taken to the *Home page - not the sign out page
